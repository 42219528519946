import clsx from 'clsx';
import type React from 'react';

interface DiscountBadgeProps {
  percentageDiscount: number;
  size?: 'small' | 'default';
  className?: string;
}

export const DiscountBadge: React.FC<DiscountBadgeProps> = ({ percentageDiscount, size = 'default', className }) => {
  if (!Number.isFinite(percentageDiscount)) {
    return null;
  }

  const badgeClasses = clsx(
    'font-bold bg-secondary-200 text-black rounded-full inline-flex items-center justify-center',
    {
      'text-[10px] w-8 h-[18px]': size === 'small',
      'text-xs sm:text-sm px-[7px] py-[3px] sm:px-[10px] sm:py-[3px]': size === 'default',
    },
    className,
  );

  return <span className={badgeClasses}>-{percentageDiscount}%</span>;
};
